<script setup>
/*
    ProductCenter
 */
import {onMounted, reactive, ref, watch} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {State, can, titlecase} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {Issue} from '@/models'
import IssueEdit from './IssueEdit.vue'
import ProductNews from './ProductNews.vue'

const props = defineProps({
    product: Object,
    issues: String
})

const Fields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {name: 'date', title: 'Opened', format: (v) => Dates.format(v, 'mmm dd yyyy'), width: '5%'},
    {name: 'type', width: '5%'},
    {
        name: 'title',
        title: 'Title',
        style: 'min-width: 300px; max-width: 400px; text-overflow: ellipsis;',
    },
    {name: 'impacted', title: 'Impacted', width: '10%'},
    {name: 'priority', title: 'Priority (CVSS)', width: '10%'},
    {name: 'cve', title: 'CVE', width: '10%'},
    // {name: 'recommend', title: 'Action'},
    // {name: 'status', width: '10%'},
    // {name: 'tags', format: (v) => v.join(', '), width: '10%'},
]

const page = reactive({
    all: [],
    fields: Fields,
    issue: {},
    issues: [],
    plan: {},
    select: null,
    showEdit: false,
    staff: can('support') && !State.app.mock
})

//  Component references
const confirm = ref(null)
const edit = ref(null)
const table = ref(null)

watch(() => props.issues, changeProduct)

onMounted(async () => {
    if (can('support') && !State.app.mock) {
        page.select = {actions: {Add: 0, Edit: 1}, multi: false, property: 'select'}
    } else {
        page.select = {actions: {View: 1}, multi: false, property: 'select'}
        page.fields = page.fields.filter((f) => f.name != 'edit')
    }
    await changeProduct()
})

onBeforeRouteLeave(() => closeEditAsk(true))

async function changeProduct() {
    let issues = await Issue.find(
        {subject: props.issues, public: page.staff ? undefined : true},
        {index: 'gs1'}
    )
    page.issues = page.all = issues.sort((a, b) => a.date - b.date).reverse()
    if (table.value) {
        await table.value.update()
    }
}

function filter(pattern) {
    if (pattern == 'releases') {
        page.issues = page.all.filter((i) => i.type == 'Release')
    } else if (pattern == 'bugs') {
        page.issues = page.all.filter((i) => i.type == 'Bug')
    } else if (pattern == 'security') {
        page.issues = page.all.filter(
            (i) =>
                i.type == 'Security' ||
                i.cve ||
                i.priority == 'High' ||
                i.priority == 'Critical' ||
                i.tags.indexOf('Security') >= 0
        )
    } else if (pattern == 'cve') {
        page.issues = page.all.filter((i) => i.tags.indexOf('CVE') >= 0)
    } else {
        page.issues = page.all
    }
}

/* KEEP
function improveDescription(text) {
    text = text.replace('# Minor', 'Minor')
    text = text.replace('## Minor', 'Minor')
    text = text.replace('##Minor', 'Minor')
    text = text.replace('# Important ', '')
    text = text.replace('# Legacy', 'Legacy')
    text = text.replace('# Internal', 'Internal')
    text = text.replace('## Recommended Action', 'Recommended Action')
    text = text.replace('### Recommended Action', 'Recommended Action')
    text = text.replace('### Change Log', 'Changes')
    text = text.replace('## Features', 'Features')
    text = text.replace('## Notes', 'Notes')
    return text
}

async function apply() {
    Progress.start('repeat')
    for (let item of ChangeLog.data) {
        let release = item.tagName.replace(/^v/, '')
        let description = improveDescription(item.description)
        let tags = [ChangeLog.subject]
        if (description.match(/security|Security|CVE|cve/)) {
            tags.push('Security')
        }
        let params = {
            type: 'Release',
            date: new Date(item.createdAt),
            title: `Release v${release}`,
            description: description,
            subject: ChangeLog.subject,
            impacted: `Prior to ${release}`,
            priority: item.priority || ChangeLog.priority,
            // CVE
            status: item.status || ChangeLog.status,
            release: release,
            tags: tags,
        }
        await Issue.create(params)
    }
    if (table.value) {
        await table.value.update()
    }
    Progress.stop()
} */

async function clicked({action, item, items, column}) {
    if (action == 'add') {
        if (!can('support')) {
            return
        }
        editIssue({})
    } else if (action == 'cell' || action == 'edit' || action == 'view') {
        editIssue(item)
    }
}

async function editIssue(issue) {
    page.issue = issue || {}
    if (issue) {
        page.showEdit = true
    } else {
        page.showEdit = false
        let issues = await Issue.find(
            {subject: props.issues, public: page.staff ? undefined : true},
            {index: 'gs1'}
        )
        page.issues = page.all = issues.sort((a, b) => a.date - b.date).reverse()
        if (table.value) {
            await table.value.update()
        }
    }
}

async function closeEditAsk(discard) {
    let issue = edit.value?.page?.issue
    if (!issue) {
        return true
    }
    if (discard && issue.id == null && issue.title) {
        if (await confirm.value.ask(`Do you want to discard changes ? `)) {
            return true
        }
        return false
    }
    return true
}
</script>

<template>
    <div class="product-center">
        <ProductNews :subject="props.issues" />

        <vu-table
            :title="`${titlecase(props.product.name || props.issues || '')} Issues`"
            name="issues"
            nodata="No Events"
            options="dynamic,filter,refilter,toolbar"
            ref="table"
            width="100%"
            :data="page.issues"
            :pageSize="25"
            :fields="page.fields"
            :select="page.select"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn class="action" @click="filter('all')">All</v-btn>
                <v-btn class="action" @click="filter('releases')">Releases</v-btn>
                <v-btn class="action" @click="filter('security')">Security</v-btn>
                <v-btn v-if="page.staff" color="accent" @click="editIssue({})">Add</v-btn>
            </template>
        </vu-table>

        <vu-panel
            v-model="page.showEdit"
            @close="editIssue"
            :widths="['700px']"
            :confirm="closeEditAsk">
            <IssueEdit ref="edit" :id="page.issue.id" :product="props.product" @input="editIssue" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.product-center {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        background: none !important;
        box-shadow: none !important;
    }
    .copy {
        float: right;
        margin-top: 4px;
    }
    .table .toolbar .filter {
        min-width: 100px;
    }
}
</style>
