<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {State, can, clone, getRoute} from '@/paks/vu-app'
import Query from '@/paks/js-query'
import AppEdit from './AppEdit.vue'

const Fields = [
    {name: 'edit', icon: (v, rec) => (rec.eval ? '' : '$edit'), width: '5%'},
    {name: 'launch', icon: '$launch'},
    {name: 'name'},
    {name: 'domain'},
    {name: 'cloud'},
    {name: 'region'},
    {name: 'id'},
    {name: 'version', format: (v) => v ? v : 'latest' },
    // {name: 'app', format: (v) => (v ? v : 'Default')},
    // {name: 'display', format: (v) => (v ? v : 'Default')},
    // {name: 'logo', format: (v) => (v ? v : 'Default')},
    {name: 'error', title: 'Deployment', format: (v) => (v ? 'Failed' : 'Operational')},
]

const page = reactive({
    canAdd: can('admin') && !State.app.suspended,
    clouds: [],
    eval: State.config.evalProductToken,
    fields: Fields,
    manager: {},
    showEdit: false,
    showImport: false,
    select: null,
})

const route = getRoute()

//  Component references
const confirm = ref(null)
const edit = ref(null)
const table = ref(null)

watch(
    () => State.app.mock,
    () => {
        if (table.value) {
            table.value.update()
        }
    }
)

onMounted(async () => {
    let clouds = State.cache.clouds.filter((c) => c.type == 'hosted' || c.type == 'dedicated')
    page.clouds = clouds = clouds.filter(c => c.id != State.config.evalCloud)
    if (page.clouds.length == 0) {
        page.canAdd = false
    }
    if (page.canAdd) {
        page.select = {
            actions: {Add: 0, Edit: 1},
            multi: true,
            property: 'select',
        }
    }
    if (route.params.id) {
        let managers = await this.getData()
        let manager = managers.find((c) => c.id == route.params.id)
        if (manager) {
            page.manager = manager
            editApp(manager)
        }
    } else if (Query('add')) {
        editApp({})
    }
    if (!can('admin')) {
        page.fields = page.fields.filter((f) => f.name != 'edit')
    }
})

onBeforeRouteLeave(() => confirmCloseEdit(true))

async function getData(args = {}) {
    if (args.reload) {
        await State.cache.update()
    }
    let managers = clone(State.cache.managers)
    if (State.app.mock) {
        managers = managers.filter((p) => p.cloudId != State.config.evalCloud)
    }
    for (let manager of managers) {
        manager.cloud = State.get('Cloud', manager.cloudId || manager.id)?.name
    }
    if (State.config.profile == 'prod' && !(can('support') && !State.app.mock)) {
        managers.unshift({
            name: 'Eval',
            region: 'us-east-1',
            domain: 'eval.ioto.me',
            cloudId: State.config.evalCloud,
            cloud: 'Eval',
            eval: true,
        })
    }
    if (State.app.mock) {
        for (let manager of managers) {
            if (manager.id) {
                manager.id = manager.id.replace(/./g, 'x')
            }
        }
    }
    return managers
}

async function clicked({action, item, items, column}) {
    if (action == 'edit') {
        if (!item.eval) {
            for (let manager of items) {
                editApp(manager)
                break
            }
        }
    } else if (action == 'cell') {
        if (column.name == 'launch') {
            launchApp(item.domain)
        } else if (can('admin')) {
            if (!item.eval) {
                editApp(item)
            }
        }
    }
}

async function editApp(manager) {
    page.showEdit = manager ? true : false
    page.manager = manager || {}
    if (!manager) {
        //  Refresh manager list
        await State.cache.update()
        //  Workaround
        if (table.value) {
            table.value.update()
        }
    }
}

async function editImport(item) {
    page.showImport = item ? true : false
    if (!item) {
        await table.value.update()
    }
}

async function launchApp(domain) {
    let url = `https://${domain}`
    window.open(url, '_blank')
}

async function confirmCloseEdit(discard) {
    return discard && (edit.value?.page?.saving || edit.value?.page?.removing) ? false : true
}
</script>

<template>
    <div class="page manager-list">
        <vu-help url="/doc/ui/apps/list.html" v-if="!page.showEdit" />

        <vu-sign
            name="manager-list-sign-1"
            title="Apps"
            subtitle="Customize device apps"
            color="accent">
            <p>
                A device App is the user interface to your device. You can create one or more apps and customize each by naming your app, changing the logo or by uploading a custom build of the device manager app code.
            </p>
            <p>
                When evaluating Ioto, you can use the Eval cloud and device manager at <b>eval.ioto.me</b> for a limited evaluation.
            </p>
        </vu-sign>

        <vu-table
            name="manager-list"
            options="dynamic,filter,refilter,toolbar"
            ref="table"
            sort="name:asc"
            :subtitle="page.clouds.length == 0 ? 'Must first create a device cloud' : null"
            title="Apps"
            width="100%"
            :data="getData"
            :fields="page.fields"
            :select="page.select"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn
                    color="accent"
                    class="mr-2"
                    id="_add"
                    @click="editApp({})"
                    :disabled="!page.canAdd">
                    Add
                </v-btn>
            </template>
        </vu-table>

        <vu-panel
            v-model="page.showEdit"
            @close="editApp"
            :widths="['600px']"
            :confirm="confirmCloseEdit">
            <AppEdit :id="page.manager.id" @input="editApp" ref="edit" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>
