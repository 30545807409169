<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, navigate, titlecase} from '@/paks/vu-app'
import {Action} from '@/models'
import ActionEdit from './ActionEdit.vue'

const DefaultAction = {
    args: {},
    context: {},
    cloudId: null,
    description: null,
    enable: true,
    error: null,
    expression: null,
    name: null,
    poll: 0,
    rearm: 0,
    severity: 'info',
    type: 'http',
}

const page = reactive({
    action: DefaultAction,
    canAdd: null,
    cloudId: null,
    clouds: null,
    fields: ref([
        {name: 'edit', icon: '$edit', width: '5%'},
        {name: 'enable', title: 'Enabled', icon: 'tick'},
        {name: 'name'},
        {name: 'description'},
        {name: 'trigger', format: v => Sources.find(t => t.value == v)?.title},
        {name: 'type', title: 'Action', format: v => titlecase(v)},
        {name: 'poll', title: 'Poll Frequency', format: (v) => formatPeriod(v)},
        {name: 'rearm', title: 'Re-Arm', format: (v) => formatPeriod(v)},
        {name: 'error'},
    ]),
    showEdit: false,
})

//  Component references
const table = ref(null)
const edit = ref(null)
const confirm = ref(null)

const Sources = [
    {title: 'Database Value (polled)', value: 'database'},
    {title: 'Database Update', value: 'sync'},
    {title: 'EventBridge Event', value: 'eventbridge'},
    {title: 'HTTP Request', value: 'http'},
    {title: 'Metric Value', value: 'metric'},
    {title: 'Schedule', value: 'schedule'},
    {title: 'User Action', value: 'user'},
]

watch(() => page.cloudId, async () => {
    State.app.setPrefer('cloudId', page.cloudId)
    if (table.value) {
        await table.value.update()
    }
})

onMounted(async () => {
    /*
        Get filtered cloud list and set preferred cloud
     */
    let clouds = State.find('Cloud').filter((c) => c.type != 'host')
    page.clouds = clouds = clouds.filter((c) => c.id != State.config.evalCloud)
    page.cloudId = State.app.prefer('cloudId')
    if (!clouds.find(c => c.id == page.cloudId)) {
        page.cloudId = null
    }
    if (!page.cloudId && page.clouds.length >= 1) {
        page.cloudId = page.clouds[0].id
    }
    page.canAdd = can('admin') && State.cache.products.length && clouds.length > 0 && !State.app.suspended

    if (table.value) {
        await table.value.update({wait: true})
    }
})


async function getData(args) {
    if (page.cloudId) {
        let actions = await Action.find({cloudId: page.cloudId})
        return actions
    }
    return []
}

async function deleteActions(items) {
    if (!(await confirm.value.ask(`Do you want to delete the selected actions?`))) {
        return
    }
    Progress.start('repeat')
    for (let item of items) {
        await Action.remove({id: item.id, cloudId: item.cloudId})
    }
    await table.value.update()
    Progress.stop()
    Feedback.info('Actions Deleted')
}

async function clicked({action, item, items, column}) {
    if (action == 'add') {
        editAction(page.action)
    } else if (action == 'edit') {
        editAction(item)
    } else if (action == 'delete') {
        if (item.name != State.auth.email) {
            await deleteActions(items)
        }
    } else if (action != 'select') {
        editAction(item)
    }
}

async function editAction(action) {
    page.showEdit = action ? true : false
    if (action) {
        delete action.select
    }
    page.action = action || DefaultAction
    if (!action) {
        await table.value.update()
    }
}

function formatPeriod(period) {
    if (period < 1000) {
        //  minutes
        period = period * 60 * 1000
    }
    period = Math.round(period)
    let {units, count} = getTimeUnits(period)
    if (period == 0) {
        return 'none'
    }
    return `${count} ${count == 1 ? units.slice(0, -1) : units}`
}

function getTimeUnits(period) {
    let units = 'minutes'
    let count = 0
    if (period >= 28 * 86400 * 1000) {
        units = 'months'
        count = period / (28 * 86400 * 1000)
    } else if (period >= 7 * 86400 * 1000) {
        units = 'weeks'
        count = period / (7 * 86400 * 1000)
    } else if (period >= 1 * 86400 * 1000) {
        units = 'days'
        count = period / (1 * 86400 * 1000)
    } else if (period >= 3600 * 1000) {
        units = 'hours'
        count = period / (3600 * 1000)
    } else if (period >= 60 * 1000) {
        units = 'minutes'
        count = period / (60 * 1000)
    }
    return {units, count: Math.round(count)}
}

async function confirmCloseEdit(discard) {
    if (discard) {
        if (await confirm.value.ask(`Do you want to discard changes? `)) {
            return true
        }
        return false
    }
    return true
}
</script>

<template>
    <div class="page action-list">
        <vu-help url="/doc/ui/automations/actions/list.html" v-if="!page.showEdit" />
        <vu-sign name="action-list-sign-1" title="Automation Actions" subtitle="" color="accent">
            <p>
                Automation actions respond to triggering conditions and invoke associated remedies.
            </p>
            <p>
                The Action list shows the automation actions configured for all each device cloud.
                Actions can be triggered via HTTP endpoints, device data values, metric conditions, schedules and user UI actions.
            </p>
            <v-alert v-if="!page.canAdd" type="error" class="mb-4">
                You need to have a non-eval device cloud and a product definition to be able to create and manage actions.
                <a @click="navigate('/clouds', {add: true})" class="addCloud">
                    Click to add a Device Cloud.
                </a>
            </v-alert>
        </vu-sign>
        
        <v-sheet class="cloud-select">
            <vu-input
                v-model="page.cloudId"
                label="Show Actions for Cloud"
                type="select"
                :items="page.clouds"
                item-title="name"
                item-value="id"
                :cols="4" />
        </v-sheet>

        <vu-table
            v-if="page.clouds"
            name="action-list"
            nodata="No Actions"
            sort="name:asc"
            ref="table"
            width="100%"
            options="filter, toolbar"
            :data="getData"
            :fields="page.fields"
            :pageSize="25"
            :select="{
                actions: {Add: page.canAdd ? 0 : undefined, Edit: 1, Delete: 2},
                filter: true,
                multi: true,
                property: 'select',
            }"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn
                    v-if="page.canAdd"
                    color="accent"
                    class="mr-2"
                    @click="editAction(DefaultAction)">
                    Add Action
                </v-btn>
            </template>
        </vu-table>

        <vu-panel v-model="page.showEdit" @close="editAction" :widths="['650px']"
          :confirm="confirmCloseEdit"
        >
            <ActionEdit v-model="page.action" :cloudId="page.cloudId" @input="editAction" ref="edit" />
        </vu-panel>
        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.action-list {
    .cloud-select {
        display: flex;
        padding: 16px 20px 16px 20px;
        margin-bottom: 20px;
        border: 1px solid rgb(var(--v-theme-border)) !important;
    }
    .table-col-action {
        .v-icon {
            padding: 0 10px 0px 0;
            vertical-align: bottom;
        }
    }
    .addCloud {
        color: white;
        font-weight: bold;
    }
}
</style>
