/*
    Display.js - Display utility routines
 */
import {State} from '@/paks/vu-state'
import {delay, getModels, getRoutes} from '@/paks/vu-app'
import {stringToBase64} from '@/paks/js-base64'

/*
    Provision the manager display
        display {data, size, name}
        css {data, size, name}
        logo {data, size, name}
        wait: timeout
        ui: display string or object for local UI which is then updated
    }
*/
export async function provision(options = {}) {
    const {Manager} = getModels()
    let manager = await Manager.get({id: options.id})
    let prior = manager.provisioned

    if (options.ui) {
        options.display = {
            data: options.ui,
            size: options.ui.length,
            name: 'display.json5',
        }
    }
    if (options.update && !options.ui && options.display?.data) {
        options.ui = options.display.data
    }
    let assets = {}
    for (let type of ['display', 'css', 'logo']) {
        let asset = options[type]
        if (asset == null) continue
        let data = asset?.data
        if (data) {
            let encoded
            let size
            if (typeof data == 'string') {
                encoded = stringToBase64(data)
                size = data.length
            } else {
                let str = JSON.stringify(data, null, 4)
                size = str.length
                encoded = stringToBase64(str)
            }
            assets[type] = {data: encoded, size, name: `${type}.json5`}
        }
    }
    manager = await Manager.provision({id: manager.id, assets})

    if (options.wait) {
        let deadline = Date.now() + options.wait
        while (Date.now() < deadline) {
            if (manager.provisioned > prior) {
                await State.cache.update()
                break
            }
            if (manager.error) {
                throw new Error('Cannot provision')
            }
            await delay(2000)
            manager = await Manager.get({id: manager.id}, {refresh: true, throw: false})
        }
        if (manager.provisioned == prior) {
            throw new Error('Timeout waiting for Provisioning')
        }
    }
    if (options.ui) {
        let display = (typeof options.ui == 'string') ? JSON.parse(options.ui) : options.ui
        await getRoutes().replaceRoutes(display)
        State.app.setDisplay(display)
        State.app.setNeed('sidebar')
        State.app.setNeed('repaint')
    }
    return manager
}

/*
    Return {views, key, view} in the State.app.display.views
*/
export function getView(path, display = State.app.display) {
    for (let [key, view] of Object.entries(display.views || [])) {
        if (view.path == path) {
            return {views: display.views, key, view}
        }
        if (view.views) {
            let found = getView(path, view)
            if (found.key) {
                return found
            }
        }
    }
    return {}
}

export async function replaceView(view, display = State.app.display) {
    let {views, key} = getView(view.path, display)
    if (key >= 0) {
        views[key] = view
    } else if (display.views[0]?.path == '/') {
        display.views[0].views.push(view)
    } else {
        display.views.push(view)
    }
}
